<template lang="pug">
  .create-matrix
    v-scrollable.matrix-grid__scroll(
            updateble
            :min-height="20")
      .matrix-grid
        .matrix-grid__left
          .matrix-grid__item(
            v-for="(column, index) in matrix.columns")
            v-baguette(
              v-for="(label, index) in column.labels"
              vertical
              :key="index"
              :color="column.color"
              :background-color="column.backgroundColor")
              v-icon {{ column.icon }}
              span
                | {{ label | translate }} {{ column.count ? `(${column.count})` : '' }}

        .matrix-grid__top
          .matrix-grid__item(
            v-for="(row, index) in matrix.rows")
            v-baguette(
              v-for="(label, index) in row.labels"
              :key="index"
              :color="row.color"
              :background-color="row.backgroundColor")
              v-icon {{ row.icon }}
              span
                | {{ label | translate }} {{ row.count ? `(${row.count})` : '' }}
          .matrix-grid__center(@click="swap")
            icon-swap
            span {{ 'pages.skill_gap.swap_rows_columns' | translate }}

</template>

<script>
import { mapActions } from 'vuex'
import IconSwap from '@/components/svg/icons/IconSwap.vue'

export default {
  name: 'MatrixConfigurationStep',
  components: {
    IconSwap
  },

  props: {
    badges: Array,
    departments: Array,
    employees: Array,
    locations: Array,
    isSelectProfileSkills: Boolean,
    myTemplateTitle: String
  },

  data: () => ({
    isSwaped: false,
    matrix: {
      rows: [],
      columns: []
    }
  }),

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    ...mapActions('insightsMatrix', [
      'createMatrix'
    ]),

    init () {
      this.matrix = {
        rows: [],
        columns: []
      }
      const labels = this.badges.map(item => item.displayName)
      this.matrix.rows.push({
        icon: 'badge',
        labels: labels,
        color: null
      })
      this.matrix.rows.push({ icon: 'badge', labels: ['ui.labels.badges'], color: '#29A687', backgroundColor: '#E3F6F1', count: this.badges.length })
      const labels2 = this.departments.map(item => item.name)
      this.matrix.columns.push({
        icon: 'profile',
        labels: labels2,
        color: null
      })
      this.matrix.columns.push({ icon: 'employees', labels: ['ui.labels.employees'], color: '#29A687', backgroundColor: '#E3F6F1', count: this.employees.length })
    },

    async onCreateMatrix () {
      this.clearErrors()
      const payload = {
        name: this.myTemplateTitle,
        isRowColumnSwapped: 0,
        isViewSwapped: 0,
        isInverted: this.isSwaped,
        employeeIds: this.employees.map(item => item.id),
        badgeIds: this.badges.map(item => item.id),
        departmentIds: this.departments.map(item => item.id),
        locationIds: this.locations.map(item => item.id),
        isSelectProfileSkills: this.isSelectProfileSkills
      }
      const { error } = await this.createMatrix(payload)
      return !error
    },

    swap () {
      const rows = this.matrix.rows
      this.matrix.rows = this.matrix.columns
      this.matrix.columns = rows
      this.isSwaped = !this.isSwaped
    }
  }
}
</script>

<style lang="scss" scoped>
  $min-size-column: 56px;
.create-matrix{
  .matrix-grid {
    display: flex;
    border: 1px solid $color-line-dotted;
    min-height: 380px;
    &__scroll{
      max-height: 380px;
    }

    &__top {
      flex: 1;
      display: flex;
      flex-direction: column;
      .matrix-grid__item{
        box-sizing: border-box;
        min-height: $min-size-column;
        border-bottom: 1px solid $color-line-dotted;
      }

    }

    &__left {
      display: flex;
    .matrix-grid__item{
        box-sizing: border-box;
        min-width: $min-size-column;
        border-right: 1px solid  $color-line-dotted;
      }
    }

    &__center {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      background-color: $website-color;
      padding: 12px;
      box-sizing: border-box;

      svg{
        width: 16px;
        height: 16px;
        margin-right: 8px;
        color: $body-text;
      }

      span {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $body-text;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &__item {
      padding: 12px;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 8px;
        .v-baguette{
          gap: 8px;
          padding: 8px;
          svg{
            width: 16px;
            height: 16px;
            margin: 0;
          }
          span{
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            color:$body-text;
          }
        }
    }
  }
}

</style>
